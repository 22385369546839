class Elementor_Services {
    static instance;

    static getInstance() {
        if (!Elementor_Services.instance) {
            Elementor_Services.instance = new Elementor_Services();
        }
        return Elementor_Services.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-services.default', ($scope) => {
            let $carousel = $scope.find('.owl-carousel');
            if ($carousel.length > 0) {
                let data = $carousel.data('settings');
                $carousel.owlCarousel(Elementor_Carousel.setupData(data));
            }
            let $wrapper = $scope.find('.elementor-services-wrapper');
            if ($wrapper.hasClass('layout_2')) {
                let $imagewraper = $scope.find('.elementor-services-image');
                let $owlnav = $scope.find('.owl-nav');
                let $width = $imagewraper.outerWidth(true) + 7;
                $owlnav.css('margin-left', $width);
            }

        });
    }
}


Elementor_Services.getInstance();